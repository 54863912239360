import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/seo';

class IndexPage extends React.Component {
  render() {
    return (
      <div>
        <SEO
          title="Sympli"
          keywords={['blog', 'gatsby', 'javascript', 'react']}
        />
        <h1>Designing for designers</h1>
        <Section>
          <p style={{ opacity: '0.66' }}>
            product design ・ UX research ・ design direction
          </p>
          <img src="./sympli/pic3.jpg" alt="Sympli preview" />
          <p>
            I’ve joined
            {' '}
            <a href="https://sympli.io">Sympli team</a>
            {' '}
in mid’2017.
            My main task was to define the vision and create concepts for the
            new Sympli product: version control system for Sketch files called “
            <a href="https://versions.sympli.io">Versions</a>
            ”.
          </p>
        </Section>
        <Section>
          <h2>Team</h2>
          <p>Product Manager / CTO: Max Ignatyev</p>
          <p>Development Lead: Kirill Kozionov</p>
          <p>Development and QA: Olga Vozchikova, Andrey Grebennikov, Alexey Martemyanov, Ilya Kozlov, Alexandra Postovalova, Alyona Didenko</p>
        </Section>
        <Section>
          <h2>My Role</h2>
          <p>
            Once again, I became a single designer in a mainly technical team. I
            was doing everything design-related: starting with marketing
            materials and ending with creating general user-flows and creating
            body copy for most interfaces. Our team used daily syncs to stay on
            the same page, and I worked with the dev team as close as I could,
            which sometimes involved working directly with the codebase. We
            worked in sprints and did regular retrospectives afterwards, trying
            to learn on our own mistakes whenever possible.
          </p>
          <p>
            My secondary goals were to maintain all the existing Sympli
            ecosystem with MAU of 40 000+: web app, plugins for Sketch, Adobe
            Photoshop, Xcode, Android Studio, later on —
            {' '}
            <a href="https://sympli.io/blog/2018/01/23/how-to-export-adobe-xd-screens-to-sympli/">
              Adobe XD
            </a>
            , and work with all the marketing assets for Sympli products: blog
            illustrations, social media, videos, etc.
          </p>
        </Section>
        <Section>
          <h2>Business Goals and Challenges</h2>
          <p>
            Sympli as a growing business needed to reach for new users, mainly
            enterprise design teams, that have made up the core of our
            eco-system — in turn, so that we can increase the revenue and do a
            better service for our client base: adding new integrations, scaling
            our tech solutions, focusing on the needs of big teams. So, here are
            the most important things we wanted to do with the Sympli's line of
            products:
          </p>
          <ol>
            <li>
              Build new product for design version control that could
              dramatically expand our user-base;
            </li>
            <li>
              Design and build an eco-system for the new product: native apps
              and plugins, plus, web service for the whole thing;
            </li>
            <li>
              Improve brand language and marketing communications, so we can
              easier onboard new users and improve retention;
            </li>
            <li>
              The existing app (Simply Handoff) with all its integrations and
              multiple platforms needed to receive a design update;
            </li>
            <li>
              Reduce the churn rate for the Sympli Handoff by improving UX;
            </li>
            <li>
              As a small agile team we had to make a lot of design iterations
              fast, so I sometimes had to work directly with our codebase and
              iterate in code;
            </li>
            <li>
              When I assumed the role of the lead designer I had to deal with a
              legacy library of design sources, mixed with badly done assets and
              screenshots embedded in vector Sketch files. This slowed the work
              dramatically, so I had to slowly rebuild the relevant files from
              the ground up.
            </li>
          </ol>
        </Section>
        <Section>
          <h2>Designing the Versions</h2>
          <img src="./sympli/pic6.jpg" alt="Sympli preview" />
          <p>
            Launching Versions app from 0 to 100% was an enormous team effort.
            We started with a rough tech prototype which served as a
            proof-of-concept, and made several iterations on Finder daemons, to
            get a grasp of what operating system is capable of.
          </p>
          <img src="./sympli/pic7.jpg" alt="Sympli preview" />
          <p>
            Then we've done multiple takes on UX design, defining most relevant
            user scenarios, and thinking about the connections between different
            services from the start.
          </p>
          <img src="./sympli/pic5.jpg" alt="Sympli preview" />
          <p>
            Versions was initially a purely native macOS app with tight OS
            integrations, but in a couple of months before the beta release,
            we've designed web service allowing our users to view design changes
            online&nbsp;— think Bitbucket or Github but for Sketch designs.
          </p>
          <img src="./sympli/pic10.jpg" alt="Sympli preview" />
          <p>
            Another important part was advertising the new app among our
            existing users and making the necessary announcements. I've done
            designs for newsletters and landing page describing the advantages
            of the new app in Sympli's family of products.
          </p>
          <p>
            I've scripted and conducted 20+ interviews showing designers from
            different client teams interactive prototypes, trying to understand
            their pain points, whether Sympli Versions help to tackle them or
            not, and adapting the scenarios accordingly.
          </p>
          <p>
            Versions launched successfully in about 6–7 months, and the team
            started improving user experience further on the live product with
            the first ≈100 beta-testers.
          </p>
        </Section>
        <Section>
          <h2>Working on the eco-system</h2>
          <img src="./sympli/pic11.jpg" alt="Sympli preview" />
          <p>
            In parallel, we worked with our existing products including handoff
            plugins for Photoshop, Sketch, and plugins for XCode or Android
            Studio helping the devs to get designs right in their IDEs of choice
            directly.
          </p>
          <p>
            I helped to design and launch a new plugin for Adobe XD which was
            right out of beta, and some of our users implemented the new product
            in their design pipeline.
          </p>
          <p>
            Sketch team introduced prototyping feature around that time, so we
            quickly adapted this new functionality to the core Sympli product,
            allowing our users to hand-off and preview Sketch prototypes right
            in our web app.
          </p>
          <p>
            With our user base being mostly enterprise design teams we wanted
            them to be able to work flexibly with the most ubiquitous task
            management system, Jira; so we've introduced Sympli live embeds in
            Jira tasks via
            {' '}
            <a href="https://sympli.io/blog/2017/09/14/sympli-now-integrates-with-atlassian-jira-to-make-project-management-even-more-powerful/">
              Sympli plugin
            </a>
            {' '}
            on the Atlassian marketplace.
          </p>
        </Section>
        <Section>
          <h2>UX research</h2>
          <img src="./sympli/pic4.jpg" alt="Sympli preview" />
          <p>
            We've started doing regular user interviews with data scientists
            working on our team, and later — with data scientists, architects,
            and managers working on our potential clients' teams. This included
            the whole thing from writing the script, to sitting next to a user
            and watching them traverse the particular scenario, analyzing the
            results (usually through a direct discussion), and then simplifying
            and adapting our interface if necessary.
          </p>
          <img src="./sympli/pic9.jpg" alt="Sympli preview" />
        </Section>
        <Section>
          <h2>Communication design</h2>
          <p>
            I created a small design system to be able to build communication
            banners, blog post previews, etc. quickly. Also, I supervised the
            overhaul of Sympli's main website with increasing the retention and
            expanding our userbase in mind. This included clarifying
            communication in everything related to payments and new users'
            registration.
          </p>
        </Section>
        <Section>
          <h2>Results</h2>
          <img src="./sympli/pic8.jpg" alt="Sympli preview" />
          <p>
            From the design perspective, all this was done by me
            single-handedly, but of course enormous tasks of native programming,
            product direction, quality assurance were all team efforts. We
            talked with our users constantly trying to understand their goals
            and frustrations better.
          </p>
          <ol>
            <li>
              sympli.io website was fully refreshed, a new, clearer, pricing
              scheme was introduced, which helped to lower the workload on the
              support;
            </li>
            <li>We've reduced the churn rate in our userbase, and the audience of the core product started to grow naturally;</li>
            <li>
              In a half-year or so we’ve managed to build a beta version of a
              design VCS called “Versions” for the macOS;
            </li>
            <li>
              We’ve made several newsletters, done ≈20 user-interviews, launched
              Versions landing page, and demoed Versions for some existing
              Sympli clients, collecting feedback in the process;
            </li>
            <li>
              Sympli plugin for Sketch was refreshed; a new plugin for the
              recently introduced Adobe XD was built;
            </li>
            <li>
              Several new service integrations were introduced — main being the
              Jira / Confluence live design embeds;
            </li>
            <li>
              I assumed a new role of an art director and copywriter, sometimes
              doing illustrations myself. I believe, that a good body copy is
              80% of a good interface, so I’ve tried to make both Sympli and
              Versions interface copy succinct and easy to understand.
            </li>
          </ol>
        </Section>
      </div>
    );
  }
}

const Section = styled.section`
  padding: 0;
  p {
    color: #000;
  }

  &:first-child {
    margin: 8rem 0 2rem;
  }

  &.typeA,
  &.typeB,
  &.typeC {
    border-radius: 0.5rem;
    padding: 1rem;
  }

  &.typeA {
    background: #ffd84d;
  }

  &.typeA p,
  &.typeB p,
  &.typeC p {
    color: #000;
  }

  & > img,
  & > iframe {
    width: 100%;
    margin: 4rem 0;
  }

  h2 {
    text-align: left;
    color: #000;
  }

  &.secondaryHolder {
    display: flex;
    justify-content: space-between;
    align-items: top;
  }

  & .secondary {
    border-radius: 0.5rem;
    padding: 1.5rem;
    background: rgba(232, 233, 239, 1);
    // background: #FFD84D;
    width: 28rem;
  }

  @media (max-width: 1024px) {
    &.secondaryHolder {
      flex-direction: column;
    }

    & .secondary {
      width: 100%;
      margin: 0 0 3rem;
    }
  }

  .secondary h2,
  .secondary p {
    text-align: left;
    color: #000;
    padding: 0;
  }

  .secondary a {
    color: #000;
    text-decoration: none;
    border-bottom: 4px solid rgba(0, 0, 0, 0.75);
  }

  .secondary a:hover {
    border-bottom: 4px solid #ffd84d;
  }
`;

export default IndexPage;
